<template>
  <div v-if="respData">
    <b-row
      v-if="$ability.can('read', this.$route.meta.resource)"
      class="match-height"
    >
      <b-col md="12">
        <b-card :title="$t('System')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Dev') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['dev'] != null ? respData['dev'] : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="switchChange('dev', $event)"
                        />
                        <b-card-text>
                          {{
                            respData['dev'] != null
                              ? respData['dev'] == true
                                ? 'เปิดระบบพัฒนา'
                                : 'ปิดระบบพัฒนา'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Maintenance') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['maintenance'] != null
                              ? respData['maintenance']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="switchChange('maintenance', $event)"
                        />
                        <b-card-text>
                          {{
                            respData['maintenance'] != null
                              ? respData['maintenance'] == true
                                ? 'เปิดการปรับปรุงระบบ'
                                : 'ปิดการปรับปรุงระบบ'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('Invite System')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Credits') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-inviteSystem-credits"
                        v-model="inviteSystem.credits"
                        placeholder="Amount"
                      />
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button
                size="sm"
                variant="primary"
                @click="inputChange('inviteSystem')"
              >
                <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
                <feather-icon
                  icon="SaveIcon"
                  size="12"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('System Line Login')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ 'Line Channel Id' }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-system-lineLogin-lineChannelId"
                        v-model="system.lineLogin.lineChannelId"
                        placeholder="lineChannelId"
                      />
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button
                size="sm"
                variant="primary"
                @click="inputChange('system')"
              >
                <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
                <feather-icon
                  icon="SaveIcon"
                  size="12"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('Shiping System') + ' (COD)'">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Status') }} : </b-td>
                    <b-td>
                      <b-form-checkbox
                        :checked="
                          respData['shipingSystem'] != null
                            ? respData['shipingSystem']['cod']['status']
                            : false
                        "
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        @change="
                          switchChange('shipingSystem.cod.status', $event)
                        "
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Amount') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-shipingSystem-cod-amount"
                        v-model="shipingSystem.cod.amount"
                        placeholder="Amount"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Calculation Method') }} : </b-td>
                    <b-td>
                      <v-select
                        id="input-lg-type"
                        v-model="shipingSystem.cod.calculationMethod"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="OptionCODCal"
                        :reduce="OptionCODCal => OptionCODCal.value"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('From') }} : </b-td>
                    <b-td>
                      <v-select
                        id="input-lg-type"
                        v-model="shipingSystem.cod.from"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="OptionCODFrom"
                        :reduce="OptionCODFrom => OptionCODFrom.value"
                      />
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button
                size="sm"
                variant="primary"
                @click="inputChange('shipingSystem')"
              >
                <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
                <feather-icon
                  icon="SaveIcon"
                  size="12"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('More Option')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 30%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Close Register') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['closeRegister']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.closeRegister', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['closeRegister'] == true
                                ? 'เปิด (ปิดปุมการลงทะเบียน)'
                                : 'ปิด (ปิดปุมการลงทะเบียน)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Open Login Page') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['openLoginPage']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.openLoginPage', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['openLoginPage'] == true
                                ? 'เปิด (เปิดหน้า login เป็นหน้าแรก)'
                                : 'ปิด (เปิดหน้า login เป็นหน้าแรก)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Skip Login Page') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['skipLoginPage']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.skipLoginPage', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['skipLoginPage'] == true
                                ? 'เปิด (ข้ามหน้า login)'
                                : 'ปิด (ข้ามหน้า login)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Multiple Login') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['multipleLogin']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.multipleLogin', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['multipleLogin'] == true
                                ? 'เปิด (Login หลายเครื่องพร้อมกัน)'
                                : 'ปิด (Login หลายเครื่องพร้อมกัน)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Confirm Order') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['confirmOrder']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.confirmOrder', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['confirmOrder'] == true
                                ? 'เปิด (ตรวจสอบออเดอร์ก่อนส่ง)'
                                : 'ปิด (ตรวจสอบออเดอร์ก่อนส่ง)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Buy now') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['buyNow']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="switchChange('moreOption.buyNow', $event)"
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['buyNow'] == true
                                ? 'เปิด (สั่งซื้อด่วน)'
                                : 'ปิด (สั่งซื้อด่วน)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Download Image') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['downloadImage']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.downloadImage', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['downloadImage'] == true
                                ? 'เปิด (ดาวน์โหลดรูปภาพ)'
                                : 'ปิด (ดาวน์โหลดรูปภาพ)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Login With Tel') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['loginWithTel']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.loginWithTel', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['loginWithTel'] == true
                                ? 'เปิด (เข้าสู่ระบบแค่เบอร์โทร)'
                                : 'ปิด (เข้าสู่ระบบแค่เบอร์โทร)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Skip Order Success') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['skipOrderSuccess']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.skipOrderSuccess', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['skipOrderSuccess'] ==
                                true
                                ? 'เปิด (ข้ามหน้าคำสั่งซื้อสำเร็จ)'
                                : 'ปิด (ข้ามหน้าคำสั่งซื้อสำเร็จ)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Show Dialog Update') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['showDialogUpdate']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.showDialogUpdate', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['showDialogUpdate'] ==
                                true
                                ? 'เปิด (แสดงข้อความแจ้งอัปเดท)'
                                : 'ปิด (แสดงข้อความแจ้งอัปเดท)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Hide Product In Home Page') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['hideProductInHomePage']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange(
                              'moreOption.hideProductInHomePage',
                              $event,
                            )
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption'][
                                'hideProductInHomePage'
                              ] == true
                                ? 'เปิด (ซ่อนสินค้าในหน้าแรก)'
                                : 'ปิด (ซ่อนสินค้าในหน้าแรก)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Hide Category In Home Page') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['hideCategoryInHomePage']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange(
                              'moreOption.hideCategoryInHomePage',
                              $event,
                            )
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption'][
                                'hideCategoryInHomePage'
                              ] == true
                                ? 'เปิด (ซ่อนหมวดหมู่ในหน้าแรก)'
                                : 'ปิด (ซ่อนหมวดหมู่ในหน้าแรก)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Hide Cart In Home Page') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['hideCartInHomePage']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange(
                              'moreOption.hideCartInHomePage',
                              $event,
                            )
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['hideCartInHomePage'] ==
                                true
                                ? 'เปิด (ซ่อนตะกร้าในหน้าแรก)'
                                : 'ปิด (ซ่อนตะกร้าในหน้าแรก)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Hide Detail Flash Sale') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['hideDetailFlashSale']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange(
                              'moreOption.hideDetailFlashSale',
                              $event,
                            )
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['hideDetailFlashSale'] ==
                                true
                                ? 'เปิด (ซ่อนรายละเอียด Flash Sale)'
                                : 'ปิด (ซ่อนรายละเอียด Flash Sale)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Button Change Language In Home Page') }} :
                    </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption'][
                                'buttonChangeLanguageInHomePage'
                              ]
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange(
                              'moreOption.buttonChangeLanguageInHomePage',
                              $event,
                            )
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption'][
                                'buttonChangeLanguageInHomePage'
                              ] == true
                                ? 'เปิด (แสดงปุ่มเปลี่ยนภาษาหน้าแรก)'
                                : 'ปิด (ซ่อนปุ่มเปลี่ยนภาษาหน้าแรก)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Stock Management In Application') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption'][
                                'stockManagementInApplication'
                              ]
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange(
                              'moreOption.stockManagementInApplication',
                              $event,
                            )
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption'][
                                'stockManagementInApplication'
                              ] == true
                                ? 'เปิด (แสดงกล่องจัดการสต็อค)'
                                : 'ปิด (ซ่อนกล่องจัดการสต็อค)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Category Function') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['categoryFunction']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.categoryFunction', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['categoryFunction'] ==
                                true
                                ? 'เปิด (ฟังก์ชั่นจัดการหมวดหมู่)'
                                : 'ปิด (ฟังก์ชั่นจัดการหมวดหมู่)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Category Fit') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['categoryFit']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.categoryFit', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['categoryFit'] == true
                                ? 'เปิด (การปรับเต็มหน้าของหมวดหมู่)'
                                : 'ปิด (การปรับเต็มหน้าของหมวดหมู่)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Delete CDN') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['deleteCDN']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.deleteCDN', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['deleteCDN'] == true
                                ? 'เปิด (การลบ Link Cache จาก Cloud)'
                                : 'ปิด (การลบ Link Cache จาก Cloud)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Customer chooses delivery') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['customerChoosesDelivery']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.customerChoosesDelivery', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['customerChoosesDelivery'] == true
                                ? 'เปิด (ลูกค้าเลือกการจัดส่ง)'
                                : 'ปิด (ลูกค้าเลือกการจัดส่ง)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Menu Special In Me Page') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['menuSpecialInMePage']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.menuSpecialInMePage', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['menuSpecialInMePage'] == true
                                ? 'เปิด (เมนูพิเศษในหน้าฉัน ของแอป)'
                                : 'ปิด (เมนูพิเศษในหน้าฉัน ของแอป)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('SafeArea Product') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['safeAreaProduct']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.safeAreaProduct', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['safeAreaProduct'] == true
                                ? 'เปิด (เว้นข้างบนของหน้าสินค้า)'
                                : 'ปิด (เว้นข้างบนของหน้าสินค้า)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Flexible Product Image') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['flexibleProductImage']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.flexibleProductImage', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['flexibleProductImage'] == true
                                ? 'เปิด (ปรับความสูงรูปภาพสินค้า ตามความสูง)'
                                : 'ปิด (ปรับความสูงรูปภาพสินค้า ตามความสูง)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Add a background color to every page') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['addBGEveryPage']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.addBGEveryPage', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['addBGEveryPage'] == true
                                ? 'เปิด (ใส่สีพื้นหลังทุกหน้า)'
                                : 'ปิด (ใส่สีพื้นหลังทุกหน้า)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Hide Price Product From User') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox
                          :checked="
                            respData['moreOption'] != null
                              ? respData['moreOption']['hidePriceProductFromUser']
                              : false
                          "
                          class="custom-control-primary ml-2"
                          name="check-button"
                          switch
                          @change="
                            switchChange('moreOption.hidePriceProductFromUser', $event)
                          "
                        />
                        <b-card-text>
                          {{
                            respData['moreOption'] != null
                              ? respData['moreOption']['hidePriceProductFromUser'] == true
                                ? 'เปิด (ซ่อนราคาสินค้า)'
                                : 'ปิด (ซ่อนราคาสินค้า)'
                              : 'ยังไม่ได้ตั้งค่า'
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('More Option')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 30%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                    <b-td />
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Padding Product') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-paddingProduct"
                        v-model="paddingProduct"
                        :placeholder="$t('Padding Product')"
                      />
                    </b-td>
                    <b-td />
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Category Count') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-categoryCount"
                        v-model="categoryCount"
                        :placeholder="$t('Category Count')"
                      />
                    </b-td>
                    <b-td />
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Category Size') }} : </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Category Icon Width')"
                        label-for="input-lg-categoryIconWidth"
                      >
                        <cleave
                          v-model="categoryIconWidth"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          :placeholder="$t('Category Icon Width')"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Category Icon Height')"
                        label-for="input-lg-categoryIconHeight"
                      >
                        <cleave
                          v-model="categoryIconHeight"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          :placeholder="$t('Category Icon Height')"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button
                size="sm"
                variant="primary"
                @click="inputChange('moreOption')"
              >
                <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
                <feather-icon
                  icon="SaveIcon"
                  size="12"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('More Option')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 30%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Fit Image') }} : </b-td>
                    <b-td>
                      <v-select
                        v-model="moreOption.fitImage"
                        label="name"
                        :clearable="false"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="OptionFitImage"
                        :reduce="OptionFitImage => OptionFitImage.value"
                        @input="
                          multiSelectChange($event, 'moreOption.fitImage')
                        "
                      />
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('Auto Message Contact Us')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Message') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-autoMessageContactUs"
                        v-model="autoMessageContactUs"
                        placeholder="autoMessageContactUs"
                      />
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button
                size="sm"
                variant="primary"
                @click="inputChange('autoMessageContactUs')"
              >
                <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
                <feather-icon
                  icon="SaveIcon"
                  size="12"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('Notification')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Key') }} (ยังไม่ได้ใช้): </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-notification-key"
                        v-model="notificationKey"
                        placeholder="Key"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Serverkey') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-notification-serverkey"
                        v-model="notificationServerkey"
                        placeholder="Serverkey"
                      />
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button
                size="sm"
                variant="primary"
                @click="inputChange('notification')"
              >
                <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
                <feather-icon
                  icon="SaveIcon"
                  size="12"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('OTP Setting')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Type') }} : </b-td>
                    <b-td>
                      <v-select
                        id="input-lg-type"
                        v-model="otpSetting.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="OptionTypeOTP"
                        :reduce="OptionTypeOTP => OptionTypeOTP.value"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr v-if="otpSetting.type === 'thaibulksms'">
                    <b-td>{{ $t('Username') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-username"
                        v-model="otpSetting.username"
                        :placeholder="$t('Username')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr v-if="otpSetting.type === 'thaibulksms'">
                    <b-td>{{ $t('Password') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-password"
                        v-model="otpSetting.password"
                        :placeholder="$t('Password')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr v-if="otpSetting.type === 'thaibulksms'">
                    <b-td>{{ $t('ExpireDate') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-expireDate"
                        v-model="otpSetting.expireDate"
                        :placeholder="$t('ExpireDate')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr v-if="otpSetting.type === 'thaibulksms'">
                    <b-td>{{ $t('SMS Amount') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-smsAmount"
                        v-model="otpSetting.smsAmount"
                        :placeholder="$t('SMS Amount')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr v-if="otpSetting.type === 'thaibulksms'">
                    <b-td>{{ $t('Message') }} : </b-td>
                    <b-td>
                      <div>
                        <b-form-tags
                          v-model="otpSetting.message"
                          no-outer-focus
                        >
                          <template
                            #default="{
                              tags,
                              inputAttrs,
                              inputHandlers,
                              addTag,
                              removeTag,
                            }"
                          >
                            <span
                              v-for="(otp, index) in otpSetting.message"
                              :key="index"
                            >{{ otp }}</span>
                            <br>
                            <b-row>
                              <b-button
                                size="sm"
                                variant="primary"
                                class="ml-2 mr-2"
                              >
                                <span class="mr-25 align-middle"> OTP </span>
                              </b-button>
                              <b-button
                                size="sm"
                                variant="primary"
                                class="mr-2"
                              >
                                <span class="mr-25 align-middle"> REF </span>
                              </b-button>
                              <b-button
                                size="sm"
                                variant="primary"
                                class="mr-2"
                              >
                                <span class="mr-25 align-middle"> MINUTE </span>
                              </b-button>
                            </b-row>

                            <b-input-group aria-controls="my-custom-tags-list">
                              <input
                                v-bind="inputAttrs"
                                placeholder="เพิ่มข้อความใหม่"
                                class="form-control"
                                v-on="inputHandlers"
                              >
                              <b-input-group-append>
                                <b-button
                                  variant="primary"
                                  @click="addTag()"
                                >
                                  {{ $t('Add') }}
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <b-col cols="12">
                              <draggable
                                v-model="otpSetting.message"
                                tag="ul"
                                class="list-group cursor-move"
                              >
                                <b-list-group-item
                                  v-for="(tag, index) in tags"
                                  :key="index"
                                  tag="li"
                                >
                                  <div class="d-flex">
                                    <!-- <b-avatar :text="listItem.name.slice(0,2)" /> -->
                                    <div class="ml-25">
                                      <b-card-text class="mb-0">
                                        {{ tag }}
                                      </b-card-text>
                                      <b-button
                                        variant="link"
                                        size="sm"
                                        :aria-controls="
                                          `my-custom-tags-tag_${tag.replace(
                                            /\s/g,
                                            '_',
                                          )}_`
                                        "
                                        class="py-0"
                                        @click="removeTag(tag)"
                                      >
                                        {{ $t('Delete') }}
                                      </b-button>
                                    </div>
                                  </div>
                                </b-list-group-item>
                              </draggable>
                            </b-col>
                          </template>
                        </b-form-tags>
                      </div>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button
                size="sm"
                variant="primary"
                @click="inputChange('otpSetting')"
              >
                <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
                <feather-icon
                  icon="SaveIcon"
                  size="12"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('Line')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Verify') }} : </b-td>
                    <b-td>
                      <b-card-text>
                        {{
                          respData['line'] != null
                            ? respData['line']['verify'] == true
                              ? 'ยืนยันแล้ว'
                              : 'ยังไม่ได้รับการยืนยัน'
                            : 'ยังไม่ได้ตั้งค่า'
                        }}
                      </b-card-text>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Key') }} : </b-td>
                    <b-td>
                      <b-form-input
                        id="input-lg-line-key"
                        v-model="lineKey"
                        :placeholder="$t('Key')"
                      />
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button
                size="sm"
                variant="primary"
                @click="inputChange('line')"
              >
                <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
                <feather-icon
                  icon="SaveIcon"
                  size="12"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTd,
  BFormTags,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/services/eCommerce/applicationSettings'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import Cleave from 'vue-cleave-component'

const STORE_MODULE_NAME = 'applicationSettings'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    draggable,
    BInputGroup,
    BInputGroupAppend,
    BFormTags,
    BListGroupItem,
    BButton,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BFormInput,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      inviteSystem: {
        credits: 0,
      },
      system: {
        lineLogin: {
          lineChannelId: '',
        },
      },
      // otpSetting
      shipingSystem: {
        cod: {
          amount: 0, // จำนวนค่าจัดส่งที่เพิ่ม
          calculationMethod: 'constant', // วิธีการคำนวนเพิ่มค่าจัดส่ง
          from: 'totalProduct',
        },
      },
      // otpSetting
      otpSetting: {
        type: 'none',
        username: '',
        password: '',
        expireDate: 0,
        smsAmount: 0,
        message: [
          'เพื่อการยืนยันรหัส ป้อนรหัสยืนยัน ',
          '$OTP_CODE',
          ' รหัสอ้างอิง #',
          '$REF',
          ' มีอายุการใช้งาน ',
          '$MINUTE',
          ' นาที',
        ],
      },
      moreOption: {
        fitImage: 'cover',
      },
      OptionTypeOTP: [
        { name: 'None', value: 'none' },
        { name: 'Firebase', value: 'firebase' },
        { name: 'Thaibulksms', value: 'thaibulksms' },
      ],
      fontFamily: 'K2D',
      OptionFontFamily: [
        { name: 'K2D', value: 'K2D' },
        { name: 'Kanit', value: 'Kanit' },
        { name: 'Nunito', value: 'Nunito' },
        { name: 'Anuphan', value: 'Anuphan' },
      ],
    }
  },
  computed: {
    OptionFitImage() {
      return [
        { name: this.$t('Contain'), value: 'contain' },
        { name: this.$t('Cover'), value: 'cover' },
        { name: this.$t('Fill'), value: 'fill' },
        { name: this.$t('Fit Height'), value: 'fitHeight' },
        { name: this.$t('Fit Width'), value: 'fitWidth' },
        { name: this.$t('None'), value: 'none' },
        { name: this.$t('Scale Down'), value: 'scaleDown' },
      ]
    },
    OptionCODCal() {
      return [
        { name: this.$t('Percent'), value: 'percent' },
        { name: this.$t('Constant'), value: 'constant' },
      ]
    },
    OptionCODFrom() {
      return [
        { name: this.$t('Total Product'), value: 'totalProduct' },
        { name: this.$t('Total Order'), value: 'totalOrder' },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData
    },
    omisePublicKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.omise.publicKey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'omise.publicKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    omiseSecretKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.omise.secretKey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'omise.secretKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    autoMessageContactUs: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.autoMessageContactUs
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'autoMessageContactUs',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    paddingProduct: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.moreOption.paddingProduct
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'moreOption.paddingProduct',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    categoryCount: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.moreOption.categoryCount
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'moreOption.categoryCount',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    categoryIconWidth: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.moreOption
          .categoryIconWidth
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'moreOption.categoryIconWidth',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    categoryIconHeight: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.moreOption
          .categoryIconHeight
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'moreOption.categoryIconHeight',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    notificationKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.notification.key
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'notification.key',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    notificationServerkey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.notification.serverkey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'notification.serverkey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    lineKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.line.key
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'line.key',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      .then(result => {
        if (result.data.data.inviteSystem != null) {
          this.inviteSystem.credits = result.data.data.inviteSystem.credits
        }
        if (result.data.data.system != null) {
          this.system.lineLogin.lineChannelId = result.data.data.system.lineLogin.lineChannelId
        }
        if (result.data.data.shipingSystem != null) {
          this.shipingSystem.cod.amount = result.data.data.shipingSystem.cod.amount
          this.shipingSystem.cod.calculationMethod = result.data.data.shipingSystem.cod.calculationMethod
          this.shipingSystem.cod.from = result.data.data.shipingSystem.cod.from
        }
        if (result.data.data.otp != null) {
          this.otpSetting.type = result.data.data.otp.type
          this.otpSetting.username = result.data.data.otp.username
          this.otpSetting.password = result.data.data.otp.password
          this.otpSetting.message = result.data.data.otp.message
          this.otpSetting.expireDate = result.data.data.otp.expireDate
          this.otpSetting.smsAmount = result.data.data.otp.smsAmount
        }
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    multiSelectChange(data, type) {
      store
        .dispatch(`${STORE_MODULE_NAME}/multiSelectChange`, { data, type })
        .then(result => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    settingPayment(data) {
      this.$router.push({
        path: `/e-commerce/application/settings/payment/${data}`,
      })
    },
    inputChange(name) {
      const obj = {}
      console.log(name)
      if (name === 'inviteSystem') {
        obj.inviteSystem = {
          credits: this.inviteSystem.credits,
        }
      } else if (name === 'system') {
        obj.system = {
          lineLogin: {
            lineChannelId: this.system.lineLogin.lineChannelId,
          },
        }
      } else if (name === 'shipingSystem') {
        obj.shipingSystem = {
          cod: {
            amount: this.shipingSystem.cod.amount,
            calculationMethod: this.shipingSystem.cod.calculationMethod,
            from: this.shipingSystem.cod.from,
          },
        }
      } else if (name === 'otpSetting') {
        obj.otp = this.otpSetting
      } else if (name === 'line') {
        obj.line = {
          key: this.lineKey,
        }
      } else if (name === 'notification') {
        obj.notification = {
          key: this.notificationKey,
          serverkey: this.notificationServerkey,
        }
      } else if (name === 'autoMessageContactUs') {
        obj.autoMessageContactUs = this.autoMessageContactUs
      } else if (name === 'omise') {
        obj.omise = {
          publicKey: this.omisePublicKey,
          secretKey: this.omiseSecretKey,
        }
      } else if (name === 'moreOption') {
        obj.moreOption = {
          paddingProduct: this.paddingProduct,
          categoryCount: this.categoryCount,
          categoryIconWidth: this.categoryIconWidth.replaceAll(',', ''),
          categoryIconHeight: this.categoryIconHeight.replaceAll(',', ''),
        }
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {
      const obj = {
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
